.app{
    background-color: #fdfdfdf6;
}

.slogan{
    font-weight: bolder;
    font-size: 3.4em;
    color: rgb(6, 9, 39);
}

.titleP{
    font-size: 1.8em;
}

.main{
    margin-top: 1em;
}

.navBarTitle{
    font-weight: bolder;
    color: blanchedalmond;
}

.navbar-dark{
    background-color:rgb(6, 9, 39);
}

.meImg{
    margin-top: 1em;
    border-radius: 50%;
    border: 0.7em solid;
    border-color:rgb(6, 9, 39);
    width: 20em;
}

.important{
    display: inline;
    font-weight: bolder;
}

.titlePart{
    vertical-align: middle;
}

.callButton{
    font-weight: bolder;
    font-size: 1.5em;
}

.infos{
    font-size: 1.5em;
    margin-top: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
    background-color: rgb(6, 9, 39);
    color: white;
}

.techLogos{
    margin-top: 1em;
    vertical-align: middle;
}

.logoImg{
    width: 10em;
    height: 10em;
}

.logoRow{
    vertical-align: middle;
}

.demo{
    font-size: 1.5em;
    margin-top: 1em;
    padding-top: 1em;
    padding-bottom: 2em;
    background-color: rgb(6, 9, 39);
    color: white;
}

.services{
    font-size: 2em;
    margin-top: 1em;
    padding-bottom: 1em;
    font-weight: bolder;
    text-align: center;
}

.demoButton{
    font-weight: bolder;
    font-size: 1.3em;
}

.skinCard{
    width: 15em;
    font-size: 0.7em;
    padding: 0.7em;
    margin-bottom: 1em;
}

.minted{
    text-align: center;
}

.imageLink{
    width: 2.5em;
    margin-left: 0.7em;
}

.imageLink-sm{
    width: 1.2em;
    margin-right: 0.3em;
}

.secondButton{
    margin-top: 1em;
}

li{
    margin-top: 0.7em;
}

.serviceCard{
    margin: 1em;
}

.serviceCard-title{
    font-weight: bolder;
    font-size: 1.3em;
}

.serviceCard-body{
    font-weight: normal;
    font-size: 0.7em;
    background-color: rgb(232, 237, 243);
}